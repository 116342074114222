<template>
  <v-container>
    <v-row>
      <v-col cols="12" id="mainCol">
        <!-- success -->
        <v-alert
          v-if="success"
          border="left"
          type="success"
          :icon="false"
          class="px-6 mt-6"
          style="max-width:980px"
        >
          <p class="text-h6">
            <strong>Form submitted successfully!</strong>
          </p>
          <p>
            Your submission of <strong>{{ currency(total) }}</strong> has been
            successfully submitted.
          </p>
          <p>
            A receipt has been emailed to <strong>{{ email }}</strong
            >. Please check your spam folder if it doesn't arrive soon.
          </p>
          <p>
            We will process your request and contact you at {{ email }} within
            <strong>3 business days.</strong>
          </p>
          <p>Thanks and have a great day!</p>
        </v-alert>
        <!-- error -->
        <v-alert
          v-if="error"
          border="left"
          type="error"
          :icon="false"
          class="px-6 mt-6"
          style="max-width:780px"
        >
          <p class="text-h6">
            <strong>Form error!</strong>
          </p>
          <p>
            The form did not submit correctly.
          </p>
          <p>
            Please email us directly at
            <strong>booking@silentauctionvacations.com</strong>
          </p>
          <p>Sorry about that!</p>
        </v-alert>
        <!-- form -->
        <v-card
          outlined
          :loading="$store.state.loading"
          v-if="!success && !error"
        >
          <v-card-text class="text--primary">
            <div class="text-h4">
              <span class="text-h4"> Package Payment and Transfer Form</span>
              <div id="toolbar">
                <v-toolbar dense rounded elevation="0" collapse>
                  <v-btn
                    icon
                    to="/"
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                  >
                    <v-icon>mdi-home</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click="$vuetify.theme.dark = !$vuetify.theme.dark"
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                  >
                    <v-icon>{{
                      $vuetify.theme.dark
                        ? "mdi-lightbulb-outline"
                        : "mdi-lightbulb"
                    }}</v-icon>
                  </v-btn>
                </v-toolbar>
              </div>
            </div>
            <p class="mt-4 text-subtitle-1">
              Use the form below to submit package and invoice payments.
            </p>
            <p class="text-subtitle-1">
              Your credit card will be charged immediately after your changes
              are approved by the supplier.
            </p>
          </v-card-text>
          <v-card-text class="text--primary">
            <v-form @submit.prevent="submit" ref="form">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    :items="[
                      'Upgrade my unit size',
                      'Transfer my package',
                      'Pay an invoice',
                      'Other',
                    ]"
                    label="I Would Like To *"
                    outlined
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                    v-model.trim="intent"
                    :rules="[textRules.required]"
                  ></v-select>
                  <v-text-field
                    label="Your Email *"
                    outlined
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                    :rules="[textRules.required, emailRules.valid]"
                    v-model.trim="email"
                    placeholder="Receipt will be sent to this email"
                  ></v-text-field>
                  <v-text-field
                    label="Your Unique ID *"
                    outlined
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                    :rules="[textRules.required]"
                    v-model.trim="uniqueID"
                  ></v-text-field>
                  <v-text-field
                    v-if="intent != 'Pay an invoice'"
                    label="Your Package Code"
                    outlined
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                    placeholder="SAV"
                    v-model.trim="packageCode"
                  ></v-text-field>
                  <v-text-field
                    v-if="intent == 'Pay an invoice'"
                    label="Organization Name *"
                    outlined
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                    :rules="[textRules.required]"
                    v-model.trim="orgName"
                  ></v-text-field>
                  <v-text-field
                    v-if="intent == 'Pay an invoice'"
                    label="Invoice # *"
                    outlined
                    type="number"
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                    :rules="[textRules.required]"
                    v-model.trim="invoiceNumber"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Name on Credit Card *"
                        outlined
                        :loading="$store.state.loading"
                        :disabled="$store.state.loading"
                        :rules="[textRules.required]"
                        v-model.trim="ccName"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Credit Card # *"
                        outlined
                        placeholder="Numbers only. No spaces."
                        type="number"
                        :loading="$store.state.loading"
                        :disabled="$store.state.loading"
                        :rules="[textRules.required]"
                        v-model.trim="ccNumber"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Expiration Date *"
                        outlined
                        placeholder="MM/YY"
                        :loading="$store.state.loading"
                        :disabled="$store.state.loading"
                        :rules="[textRules.required]"
                        v-model.trim="ccExpDate"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Card Code (CVC) *"
                        outlined
                        type="number"
                        :loading="$store.state.loading"
                        :disabled="$store.state.loading"
                        :rules="[textRules.required]"
                        v-model.trim="ccCVC"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Billing Zip Code *"
                        outlined
                        :loading="$store.state.loading"
                        :disabled="$store.state.loading"
                        :rules="[textRules.required]"
                        v-model.trim="billingZip"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Your Charge Total *"
                        outlined
                        type="number"
                        :loading="$store.state.loading"
                        :disabled="$store.state.loading"
                        :rules="[textRules.required]"
                        v-model.trim="total"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-img src="@/assets/ccLogos.jpg" max-width="200" />
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        label="I have read and agree to the website terms and
                            conditions *"
                        :disabled="$store.state.loading"
                        :rules="[textRules.required]"
                        v-model.trim="understandTerms"
                      ></v-checkbox>
                      <v-checkbox
                        label="I understand that these charges are non-refundable
                            regardless of whether I use my travel package or
                            not. I authorize Silent Auctions, LLC. to charge my
                            credit card provided above for total charges listed
                            here. I understand that my information will be saved
                            to file for purchase of upgrade and/or fees listed
                            here, and card information will not be saved to file
                            for future transactions. Traveler authorizes use of
                            this credit card information and guarantees this
                            payment in full. The credit card identified shall be
                            charged the “Your Charge Total” amount stated. *"
                        :disabled="$store.state.loading"
                        :rules="[textRules.required]"
                        v-model.trim="understandNoRefunds"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <div style="width:200px">
              <v-btn
                x-large
                depressed
                color="primary"
                block
                :loading="$store.state.loading"
                :disabled="$store.state.loading"
                @click.prevent="submit"
                >Submit</v-btn
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import formRules from "@/mixins/formRules";
import firebase from "@/plugins/firebase";
export default {
  mixins: [formRules],
  data: () => ({
    intent: "",
    email: "",
    uniqueID: "",
    packageCode: "",
    ccName: "",
    ccNumber: "",
    ccExpDate: "",
    ccCVC: "",
    billingZip: "",
    total: "",
    understandTerms: false,
    understandNoRefunds: false,
    success: false,
    error: false,
    orgName: "",
    invoiceNumber: "",
  }),
  methods: {
    currency(val) {
      if (!val || val == "") return;
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter.format(val);
    },
    async submit() {
      // not valid
      if (!this.$refs.form.validate()) {
        this.$toast.open({
          message: "Please check form for errors",
          type: "error",
        });
        this.$store.state.loading = false;
        return;
      }
      this.$store.state.loading = true;

      // send to gmail via lambda
      let data = {
        intent: this.intent,
        email: this.email,
        uniqueID: this.uniqueID,
        packageCode: this.packageCode,
        ccName: this.ccName,
        ccNumber: this.ccNumber,
        ccExpDate: this.ccExpDate,
        ccCVC: this.ccCVC,
        billingZip: this.billingZip,
        total: this.currency(this.total),
        understandTerms: this.understandTerms,
        understandNoRefunds: this.understandNoRefunds,
        orgName: this.orgName,
        invoiceNumber: this.invoiceNumber,
      };

      try {
        let url =
          process.env.NODE_ENV.trim() == "development"
            ? "http://localhost:3000/"
            : "https://dsnrn4w4ze.execute-api.us-east-1.amazonaws.com/default/travelerPortalPaymentForm";

        let request = await axios.post(url, data);
        // form submission error
        if (
          !request.data ||
          request.data == undefined ||
          request.data == null ||
          request.data != "Email Sent Successfully"
        )
          this.error = true;
        // form success
        this.$store.state.loading = false;
        this.error = false;
        this.success = true;
        // add admin notification
        await firebase
          .firestore()
          .collection("adminNotifications")
          .add({
            archived: false,
            dateAdded: new Date().toISOString().substring(0, 10),
            dateArchived: "",
            text: `[Payment Submitted - Traveler Portal] Package Payment & Transfer Form - ${this.email}`,
          });
      } catch (error) {
        this.$store.state.loading = false;
        this.error = true;
        this.success = false;
      }
    },
  },
};
</script>

<style>
.v-toast__item {
  font-family: "Roboto", sans-serif;
}

#mainCol {
  position: relative;
}

#toolbar {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 5px;
  margin-right: 5px;
}

@media (max-width: 980px) {
  #toolbar {
    display: none;
  }
}
</style>
